<ng-container *transloco="let t">
    <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden bg-card">
        <!-- Title -->
        <div
            class="flex justify-between items-center p-8 md:px-15 lg:px-30 xl:px-40 md:py-8 lg:py-8 xl:py-8"
        >
            <div class="text-4xl font-semibold tracking-tight leading-tight">
                <span>
                    {{ t("LIST-OF-USERS-WHO-HAVE-HAD-AN-EMERGENCY") }}
                </span>
            </div>
        </div>
        <div
            class="flex flex-col flex-1 overflow-auto p-8 md:px-15 lg:px-30 xl:px-40"
        >
            <div>
                <h2
                    id="page-heading"
                    class="text-xl font-semibold tracking-tight leading-tight mb-2"
                    data-cy="GeoLocationHeading"
                >
                    <span>{{ t("EMERGENCIES") }}</span>
                </h2>

                @if (dataSource.data?.length === 0) {
                <div
                    class="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
                    id="no-result"
                >
                    <span>{{ t("NO-EMERGENCIES-DURING-THE-LAST-HOUR") }}</span>
                </div>
                }

                <!-- table -->

                @if (dataSource?.data && dataSource.data!.length != 0) {
                <!-- <div class="table-responsive" id="entities_2">
                    <table
                        class="table table-striped"
                        aria-describedby="page-heading"
                    >
                        <thead>
                            <tr>
                                <th scope="col">
                                    {{ t("EMERGENCY-CALL-INPUT") }}
                                </th>
                                <th scope="col">{{ t("AP-NUMBER") }}</th>
                                <th scope="col">{{ t("SALUTATION") }}</th>
                                <th scope="col">{{ t("TITLE") }}</th>
                                <th scope="col">{{ t("NICK-NAME") }}</th>
                                <th scope="col">{{ t("FIRST-NAME") }}</th>
                                <th scope="col">{{ t("SURNAME") }}</th>
                                <th scope="col">{{ t("REASON") }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            @for (umoPstnFallbackCall of
                            lastUmoPstnFallbackList; track
                            umoPstnFallbackCall.id; let i = $index) {
                            <tr
                                data-cy="entityTable"
                                [ngClass]="{
                                    'clickable-row': true,
                                    'row-green':
                                        umoPstnFallbackCall.emergencyColor ===
                                        'GREEN',
                                    'row-red':
                                        umoPstnFallbackCall.emergencyColor !==
                                        'GREEN'
                                }"
                                [id]="'row-' + i"
                                (click)="
                                    openInNewTab(
                                        umoPstnFallbackCall.urlAgentAccessToken
                                    )
                                "
                            >
                                <td>
                                    {{ umoPstnFallbackCall.startedAt }}
                                </td>
                                <td>{{ umoPstnFallbackCall.apNumber }}</td>
                                <td>{{ umoPstnFallbackCall.salutation }}</td>
                                <td>{{ umoPstnFallbackCall.title }}</td>
                                <td>{{ umoPstnFallbackCall.nickName }}</td>
                                <td>{{ umoPstnFallbackCall.firstName }}</td>
                                <td>{{ umoPstnFallbackCall.lastName }}</td>
                                <td>{{ umoPstnFallbackCall.reason }}</td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div> -->

                <div class="table-container flex-1 overflow-auto">
                    <table mat-table [dataSource]="dataSource">
                        <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

                        <!-- @for (item of displayedColumnsData; track item.id; let i
                        = $index) {
                        <ng-container [matColumnDef]="item.key">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ t(item.value) }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element[item.key] }}
                            </td>
                        </ng-container>
                        } -->

                        <ng-container matColumnDef="startedAt">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ t("EMERGENCY-CALL-INPUT") }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.startedAt | date : medium }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="apNumber">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ t("AP-NUMBER") }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.apNumber }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="salutation">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ t("SALUTATION") }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.salutation }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ t("TITLE") }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.title }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="nickName">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ t("NICK-NAME") }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.nickName }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="firstName">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ t("FIRST-NAME") }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.firstName }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="lastName">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ t("SURNAME") }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.lastName }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="reason">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ t("REASON") }}
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.reason }}
                            </td>
                        </ng-container>

                        <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumns; sticky: true"
                        ></tr>
                        <tr
                            mat-row
                            *matRowDef="let row; columns: displayedColumns"
                            [ngClass]="{
                                'clickable-row': true,
                                'row-green': row.emergencyColor === 'GREEN',
                                'row-red': row.emergencyColor !== 'GREEN'
                            }"
                            (click)="redirect(row.urlAgentAccessToken)"
                        ></tr>
                    </table>
                </div>
                }
            </div>
        </div>
    </div>
</ng-container>
