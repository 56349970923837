import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IEmergency } from 'app/modules/shared/models/emergency.model';
import { environment } from 'environments/environment';
import { createRequestOption } from '../request/request-util';

// import { isPresent } from 'app/core/util/operators';

export type EntityResponseType = HttpResponse<IEmergency>;
export type EntityArrayResponseType = HttpResponse<IEmergency[]>;

@Injectable({ providedIn: 'root' })
export class EmergencyService {
  protected repoURL = environment.repoUrl;
      protected resourceUrl = this.repoURL + '/api/emergencies';

  constructor(protected http: HttpClient) {}

//   create(emergency: IEmergency): Observable<EntityResponseType> {
//     const copy = this.convertDateFromClient(emergency);
//     return this.http
//       .post<IEmergency>(this.resourceUrl, copy, { observe: 'response' })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   update(emergency: IEmergency): Observable<EntityResponseType> {
//     const copy = this.convertDateFromClient(emergency);
//     return this.http
//       .put<IEmergency>(`${this.resourceUrl}/${getEmergencyIdentifier(emergency) as number}`, copy, { observe: 'response' })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   partialUpdate(emergency: IEmergency): Observable<EntityResponseType> {
//     const copy = this.convertDateFromClient(emergency);
//     return this.http
//       .patch<IEmergency>(`${this.resourceUrl}/${getEmergencyIdentifier(emergency) as number}`, copy, { observe: 'response' })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   find(id: number): Observable<EntityResponseType> {
//     return this.http
//       .get<IEmergency>(`${this.resourceUrl}/${id}`, { observe: 'response' })
//       .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
//   }

//   query(req?: any): Observable<EntityArrayResponseType> {
//     const options = createRequestOption(req);
//     return this.http
//       .get<IEmergency[]>(this.resourceUrl, { params: options, observe: 'response' })
//       .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
//   }

//   delete(id: number): Observable<HttpResponse<{}>> {
//     return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
//   }

//   addEmergencyToCollectionIfMissing(
//     emergencyCollection: IEmergency[],
//     ...emergenciesToCheck: (IEmergency | null | undefined)[]
//   ): IEmergency[] {
//     const emergencies: IEmergency[] = emergenciesToCheck.filter(isPresent);
//     if (emergencies.length > 0) {
//       const emergencyCollectionIdentifiers = emergencyCollection.map(emergencyItem => getEmergencyIdentifier(emergencyItem)!);
//       const emergenciesToAdd = emergencies.filter(emergencyItem => {
//         const emergencyIdentifier = getEmergencyIdentifier(emergencyItem);
//         if (emergencyIdentifier == null || emergencyCollectionIdentifiers.includes(emergencyIdentifier)) {
//           return false;
//         }
//         emergencyCollectionIdentifiers.push(emergencyIdentifier);
//         return true;
//       });
//       return [...emergenciesToAdd, ...emergencyCollection];
//     }
//     return emergencyCollection;
//   }

  getUserEmergencyIdByEmergencyAgentToken(emergencyAgentToken: string, req?: any): Observable<EntityResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IEmergency>(`${this.resourceUrl}/emergency-agent-token/${emergencyAgentToken}`, {
        params: options,
        observe: 'response',
      })
    //   .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

//   protected convertDateFromClient(emergency: IEmergency): IEmergency {
//     return Object.assign({}, emergency, {
//       startedAt: emergency.startedAt?.isValid() ? emergency.startedAt.toJSON() : undefined,
//     });
//   }

//   protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
//     if (res.body) {
//       res.body.startedAt = res.body.startedAt ? dayjs(res.body.startedAt) : undefined;
//     }
//     return res;
//   }

//   protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
//     if (res.body) {
//       res.body.forEach((emergency: IEmergency) => {
//         emergency.startedAt = emergency.startedAt ? dayjs(emergency.startedAt) : undefined;
//       });
//     }
//     return res;
//   }
}
