import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class WifiSettingsService {
    protected repoURL = environment.repoUrl;

    constructor(private http: HttpClient) {}

    isValidWifiSettingsToken(token: string): Observable<any> {
        return this.http.get<any>(
            `${this.repoURL}/api/wifi-setting-token/is-valid/${token}`
        );
    }

    setWifiPassword(token: string, payload): Observable<any> {
        return this.http.post<any>(
            `${this.repoURL}/api/wif-setting/wifi-setting-token/${token}`,
            payload
        );
    }

    //     POST
    // /api/user/{userId}/wifi-settings/{deviceUuid}

    // POST
    // /api/user/{userId}/wifi-settings/request-via-sms/user-id/{targetUserId}
    sendtWifiPasswordRequestViaSms(
        userId: number,
        targetUserId: number
    ): Observable<any> {
        return this.http.post<any>(
            `${this.repoURL}/api/user/${userId}/wifi-settings/request-via-sms/user-id/${targetUserId}`,
            { params: { userId, targetUserId }, observe: 'response' }
        );
    }
    // POST
    // /api/user/{userId}/wifi-settings/request-via-sms/phone-number/{phoneNumber}

    // POST
    // /api/user/{userId}/wifi-settings/request-via-email/user-id/{targetUserId}
    sendtWifiPasswordRequestViaEmail(
        userId: number,
        targetUserId: number
    ): Observable<any> {
        return this.http.post<any>(
            `${this.repoURL}/api/user/${userId}/wifi-settings/request-via-email/user-id/${targetUserId}`,
            { params: { userId, targetUserId }, observe: 'response' }
        );
    }

    // POST
    // /api/user/{userId}/wifi-settings/request-via-email/email/{targetEmail}
}
