<div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden bg-card">
    <div class="flex flex-auto flex-col items-center p-6 sm:p-10">
        <div class="flex w-full max-w-4xl flex-col">
            <ng-container *transloco="let t">
                <div
                    class="flex justify-between items-center p-8 pr-6 sm:py-10"
                >
                    <!-- Title -->
                    <div
                        class="text-3xl font-semibold tracking-tight leading-tight"
                    >
                        {{ t("WIFI-CONFIGURATION") }}
                    </div>

                    <div class="flex items-center">
                        <!-- Submit button -->
                        <button
                            mat-flat-button
                            color="primary"
                            class="w-50"
                            [disabled]="wifiPasswordForm.disabled"
                            (click)="save()"
                        >
                            @if(!wifiPasswordForm.disabled){
                            <span> {{ t("SAVE") }} </span>
                            } @if (wifiPasswordForm.disabled) {
                            <mat-progress-spinner
                                [diameter]="24"
                                [mode]="'indeterminate'"
                            ></mat-progress-spinner>
                            }
                        </button>
                    </div>
                </div>

                <div
                    class="flex flex-col flex-1 overflow-auto p-8 pr-6 sm:py-10"
                >
                    <div
                        class="text-lg font-medium tracking-tight leading-tight mb-2"
                    >
                        {{ t("PLEASE-SHARE-WIFI-NAME-AND-PASSWORD") }}
                    </div>

                    <!-- Alert -->
                    @if(showAlert){
                    <fuse-alert
                        class="mb-2"
                        [appearance]="'outline'"
                        [showIcon]="false"
                        [type]="alert.type"
                        [@shake]="alert.type === 'error'"
                    >
                        {{ t(alert.message) }}
                    </fuse-alert>
                    }

                    <!-- form -->
                    <form [formGroup]="wifiPasswordForm">
                        <mat-form-field class="w-full mb-1">
                            <mat-label>{{ t("SSID") }}</mat-label>
                            <input
                                id="ssid"
                                matInput
                                type="text"
                                formControlName="ssid"
                            />
                            @if ( wifiPasswordForm.get('ssid')
                            .hasError('required') ) {
                            <mat-error>
                                {{ t("SSID-IS-REQUIRED") }}
                            </mat-error>
                            }
                        </mat-form-field>

                        <mat-form-field class="w-full">
                            <mat-label>{{ t("PASSWORD") }}</mat-label>
                            <input
                                id="password"
                                matInput
                                type="password"
                                formControlName="password"
                            />
                            @if ( wifiPasswordForm .get('password')
                            .hasError('minlength') ) {
                            <mat-error>
                                {{
                                    t("PASSWORD-MUST-BE-AT-LEAST-8-CHARACTERS")
                                }}
                            </mat-error>
                            }
                        </mat-form-field>
                    </form>
                </div>
            </ng-container>
        </div>
    </div>
</div>
